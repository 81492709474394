import React from "react";

const About = () => {
  return (
    <>
      <main id="about">
        <h1 className="lg-heading">
          About
          <span className="text-secondary">Me</span>
        </h1>
        <h2 className="sm-heading">Let me tell you a few things...</h2>
        <div className="about-info">
          <img src="/img/images.jpeg" alt="Lozah Hanem" className="bio-image" />

          <div className="bio">
            <h3 className="text-secondary">What I can do</h3>
            <p>
              - Narrate stories, documentaries, and presentations.
              <br />
              - Create character voices for cartoons, video games, and radio
              dramas.
              <br />
              - Record educational content for e-learning modules and training
              videos.
              <br />
              - Localize content by translating and dubbing for global
              audiences.
              <br />
            </p>
          </div>
          <div className="job job-1">
            <h3 className="text-secondary">Lorem Ipsum</h3>
            <h6>Neque porro quisquam est qui </h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>

          <div className="job job-2">
            <h3 className="text-secondary">Lorem Ipsum</h3>
            <h6>Neque porro quisquam est qui </h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>

          <div className="job job-3">
            <h3 className="text-secondary">Lorem Ipsum</h3>
            <h6>Neque porro quisquam est qui </h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>

        {/* <div className="certificate">
          <h3 className="text-secondary">FCC Certifications</h3>
          <div className="certs">
            <div className="cert">
              <a
                href="https://www.freecodecamp.org/certification/m-magdy93/javascript-algorithms-and-data-structures"
                target="_blank"
                rel="noopener noreferrer"
              >
                - JavaScript Algorithms and Data Structures
              </a>
            </div>
            <div className="cert">
              <a
                href="https://www.freecodecamp.org/certification/m-magdy93/apis-and-microservices"
                target="_blank"
                rel="noopener noreferrer"
              >
                - APIs and Microservices (NodeJs - MongoDB)
              </a>
            </div>
          </div>
        </div> */}
      </main>

      <footer id="main-footer">Copyright &copy; 2024</footer>
    </>
  );
};
export default About;
