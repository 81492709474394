import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [hideMenu, setMenu] = useState(true);
  const onClick = () => setMenu(!hideMenu);

  return (
    <header>
      <div className={`menu-btn ${!hideMenu ? "close" : ""}`} onClick={onClick}>
        <div className="btn-line"></div>
        <div className="btn-line"></div>
        <div className="btn-line"></div>
      </div>

      <nav className={`menu ${!hideMenu ? "show" : ""}`}>
        <div className={`menu-branding ${!hideMenu ? "show" : ""}`}>
          <div className="portrait"></div>
        </div>
        <ul className={`menu-nav ${!hideMenu ? "show" : ""}`}>
          <li className={`nav-item ${!hideMenu ? "show" : ""}`}>
            <NavLink
              to="/"
              className="nav-link"
              exact
              activeClassName="current"
              onClick={onClick}
            >
              {" "}
              Home{" "}
            </NavLink>
          </li>
          <li className={`nav-item ${!hideMenu ? "show" : ""}`}>
            <NavLink
              to="/about"
              className="nav-link"
              activeClassName="current"
              onClick={onClick}
            >
              {" "}
              About Me{" "}
            </NavLink>
          </li>
          <li className={`nav-item ${!hideMenu ? "show" : ""}`}>
            <NavLink
              className="nav-link"
              to="/work"
              activeClassName="current"
              onClick={onClick}
            >
              {" "}
              My Work{" "}
            </NavLink>
          </li>
          <li className={`nav-item ${!hideMenu ? "show" : ""}`}>
            <NavLink
              to="/contact"
              className="nav-link"
              activeClassName="current"
              onClick={onClick}
            >
              Contact Me
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};
export default Header;
