import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Work from './components/Work';
import Contact from './components/Contact';

function App() {
  return (
    <>
		<Router basename={process.env.PUBLIC_URL}>
			<Header />
			<Switch>
				<Route exact path='/' component={Home} />
				<Route path='/about' component={About} />
				<Route path='/work' component={Work} />
				<Route path='/contact' component={Contact} />
			</Switch>
		</Router>
    </>
  );
}

export default App;