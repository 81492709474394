import React from "react";

const Contact = () => {
  return (
    <>
      <main id="contact">
        <h1 className="lg-heading">
          Contact
          <span className="text-secondary">Me</span>
        </h1>
        <h2 className="sm-heading">This is how you can reach me...</h2>
        <div className="contact-details">
          <div>
            <span className="text-secondary">Email: </span> lozaEmail@gmail.com
          </div>
          <div>
            <span className="text-secondary">Phone: </span> (012) 1234-5678
          </div>
          <div>
            <span className="text-secondary">LinkedIn: </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com"
            >
              <i className="fab fa-linkedin fa-1x"></i>
            </a>
          </div>
        </div>
      </main>

      <footer id="main-footer">Copyright &copy; 2024</footer>
    </>
  );
};
export default Contact;

/*
		<!-- <a href="#!"><i className="fab fa-github fa-2x"></i></a> -->
		<!-- <a href="#!"><i className="fab fa-facebook fa-2x"></i></a> -->

*/
