import React from "react";

const Home = () => {
  return (
    <div className="landing">
      <div className="dark-overlay">
        <main id="home">
          <h1 className="lg-heading">
            Lozah <span className="text-secondary">Hanem</span>
          </h1>
          <h2 className="sm-heading">Voice over Artist</h2>
          <h3 className="sm-heading">
            {/* Some small sentences about voice over */}
            Proficient in various accents, dialects, and character voices.
          </h3>
          <div className="icons">
            <a href="https://www.linkedin.com/in">
              <i className="fab fa-linkedin fa-2x"></i>
            </a>
          </div>
        </main>
      </div>
    </div>
  );
};
export default Home;
