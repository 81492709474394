import React from "react";

const Work = () => {
  return (
    <>
      <main id="work">
        <h1 className="lg-heading">
          My
          <span className="text-secondary">Work</span>
        </h1>
        <h2 className="sm-heading">Check out some of my projects...</h2>
        <div className="profiles">
          <div className="profile bg-light">
            <img className="round-img" src="/img/work2.png" alt="" />
            <div>
              <h2>Commercial Advertisement:</h2>
              <span>(dolorem ipsum quia dolor sit amet)</span>
              <p>
                {" "}
                voiced a 30-second commercial spot for a local coffee shop,
                emphasizing the brand's unique blend and inviting atmosphere.
              </p>
            </div>
            <ul style={{ float: "right" }}>
              <li className="text-primary">
                <i className="fas fa-check"> </i> Voice Over{" "}
              </li>
              <li>
                <a
                  href="!#"
                  className="text-secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Demo
                </a>
              </li>
            </ul>
          </div>

          <div className="profile bg-light">
            <img className="round-img" src="/img/work1.jpeg" alt="" />
            <div>
              <h2>Audiobook Narration:</h2>
              <span>(dolorem ipsum quia dolor sit amet)</span>
              <p>
                Narrated an audiobook adaptation of a popular novel, bringing
                characters to life through expressive storytelling and engaging
                narration.{" "}
              </p>
            </div>
            <ul style={{ float: "right" }}>
              <li className="text-primary">
                <i className="fas fa-check"> </i> Voice Over{" "}
              </li>
              <li>
                <a
                  href="!#"
                  className="text-secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Demo
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <footer id="main-footer">Copyright &copy; 2024</footer>
    </>
  );
};
export default Work;
